@import "variables";

$border-radius: 0.5rem;
$drop-shadow: 0 0.1rem rgba(0, 0, 0, 0.2);
$border-filet: solid 0.1rem var(--dark-transparency-color);
$left-panel-size: 34rem;

/*
* Common blocs
*/

// Tab content radius
@mixin tab-content-radius {
  border-radius: 0 0 0 1rem;
}

// Section header (h2 title container)
@mixin section-header {
  display: flex;
  align-items: center;
  // margin-bottom: 2rem;
}
// Footer
@mixin footer-add {
  margin-left: -5.8333rem;
  margin-right: -1.6667rem;
  margin-bottom: -5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-color);
  position: relative;
}

// Dialog close
@mixin dialog-close {
  width: 4rem;
  height: 4rem;
  color: var(--base-color);
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: -0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Key icon
@mixin lock-key {
  width: 2.5rem;
  height: 2rem;
  font-size: 1.2rem;
  color: var(--secondary-color);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 1rem;
}

// Section handler
@mixin section-handler($handle-color, $handle-border-color) {
  display: flex;
  background-color: $handle-color;
  border-left: 0.3rem solid $handle-border-color;
  width: 5rem;
  height: 3rem;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;

  i {
    color: white;
    font-size: 1.2rem;
  }
}

//Main p-datatable-footer
@mixin main-tabs {
  font-family: inherit;
  border-right: 0.1rem solid var(--light-border-color);
  font-size: 1.2rem;
  height: 5rem;
  line-height: 5rem;
  border-radius: $border-radius $border-radius 0 0;

  position: relative;
  flex: 1;
  text-align: center;
  color: #808183;
  text-decoration: none;
}

@mixin main-tab-active {
  background-color: white;
  border-right-color: transparent;
  color: var(--base-color);
  border-top: 0.3rem solid var(--base-color);
  overflow: visible;

  // &:before {
  //   content:" ";
  //   height: .3rem;
  //   background-color: $base-color;
  //   width: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
  &:before,
  &:after {
    content: " ";
    height: 1rem;
    width: 1rem;
    background-image: url("//d2qqs847dby5cf.cloudfront.net/deployments/dev/2.52.4-1e950a3-eec9f2c/en/images/corner-tab.svg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: -1rem;
  }
  &:after {
    background-position: right;
    bottom: 0;
    left: auto;
    right: -1rem;
  }
}

.bg-white {
  background-color: white;
}

.bg-section {
  background-color: var(--section-color);
}

// If browser does not support -webkit-line-clamp
.line-clamp-2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@supports (display: -webkit-box) {
  .line-clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
  }
}
